import { Component } from '@angular/core';
import { ZanattaService } from '../services/zanatta.service';
import { Router } from '@angular/router';
import { Role } from '../config/niveis.enum';

@Component({
  selector: 'app-p-info-redirect',
  templateUrl: './p-info-redirect.component.html',
  styleUrls: ['./p-info-redirect.component.scss'],
})
export class PInfoRedirectComponent {
  constructor(private router: Router, private zanattaService: ZanattaService) {
    let returnUrl = '/login';

    if (this.zanattaService.isAuthAuthorization()) {
      switch (this.zanattaService.getNivelUsuario()) {
        case Role.USUARIO_ADMINISTRADOR:
        case Role.USUARIO_ADMINISTRADOR_STAC:
          returnUrl = '/admin';
          break;
        case Role.USUARIO_ZANATTA:
        case Role.USUARIO_ZANATTA_PROPRIETARIO:
        case Role.USUARIO_VISUALIZADOR:
          returnUrl = '/zanatta';
          break;
        default:
          break;
      }
    }

    this.router.navigate([returnUrl]);
  }
}
