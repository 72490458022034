import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PLoginComponent } from './p-login/p-login.component';
import { PInfoComponent } from './p-info/p-info.component';
import { ZanattaGuardService } from './guards/zanatta-guard.service';
import { AdminGuardService } from './guards/admin-guard.service';
import { PModulosNavComponent } from './p-modulos-nav/p-modulos-nav.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { PInfoRedirectComponent } from './p-info-redirect/p-info-redirect.component';

const routes: Routes = [
  {
    path: 'login',
    component: PLoginComponent,
  },
  {
    path: 'info',
    component: PInfoComponent,
  },
  {
    path: 'info_',
    component: PInfoRedirectComponent,
  },
  {
    path: '',
    component: PModulosNavComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'zanatta',
        loadChildren: () =>
          import('./mp-zanatta/mp-zanatta.module').then(
            (m) => m.MpZanattaModule
          ),
        canActivate: [ZanattaGuardService],
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./mp-admin/mp-admin.module').then((m) => m.MpAdminModule),
        canActivate: [AdminGuardService],
      },
    ],
  },

  {
    path: '**',
    redirectTo: 'info',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
