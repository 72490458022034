import { MatPaginatorIntl } from '@angular/material/paginator';

const ptBrRangeLabel = (page: number, pageSize: number, length: number) => {
  return `Página ${page + 1} de ${Math.ceil(length / pageSize)}`;
};

export function getPtBrPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Itens por página:';
  paginatorIntl.nextPageLabel = 'Próxima';
  paginatorIntl.previousPageLabel = 'Anterior';
  paginatorIntl.getRangeLabel = ptBrRangeLabel;
  paginatorIntl.firstPageLabel = 'Primeira';
  paginatorIntl.lastPageLabel = 'Última';

  return paginatorIntl;
}
