
<div class="row">
  <div class="col-12 col-sm-12 col-md-6 col-lg-8 pagina-login" >
    <div class="col-6 col-sm-5 col-lg-3" style="padding: 0px;" hidden>
      <img
      class="img-fluid"
      src="/assets/zanatta_logo.png"
      alt="Logo Avestac"
    />
    </div>

  </div>

  <div class="col-12 col-sm-12 col-md-6 col-lg-4 login text-font">
    <form
      [formGroup]="form"
      style="display: inline-block"
      class="col-10 col-sm-10 col-lg-10 form text"
      (submit)="submit()"
    >
      <h2>Bem-vindo</h2>

      <mat-form-field >
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" />
      </mat-form-field>

      <mat-form-field >
        <mat-label>Senha</mat-label>
        <input matInput type="password" formControlName="senha"  />
      </mat-form-field>

      <button
        disabled="{{ loading }}"
        mat-raised-button
        color="primary"
        class="col-12 stac-button-red"
      >
        ENTRAR
      </button>
    </form>
  </div>
</div>
