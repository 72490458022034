import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Observable, catchError, map, mergeMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InterceptionService {
  constructor(private router: Router, public firebaseAuth: AngularFireAuth) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.firebaseAuth.idToken.pipe(
      mergeMap((token: any) => {
        if (token) {
          // console.log('[token]', token);
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
          });
        }
        return next.handle(request).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              //console.log('event--->>>', event);
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              this.router.navigate(['/info']);
            }
            // this.errorDialogService.openDialog(data);
            return throwError(error);
          })
        );
      })
    );
  }
}
