import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ZanattaService } from '../services/zanatta.service';

@Injectable({
  providedIn: 'root',
})
export class ZanattaGuardService implements CanActivate {
  constructor(private zanattaService: ZanattaService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.zanattaService.isAuthZanattaAuthorization()) {
      return true;
    } else {
      this.router.navigate(['/info_'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
}
