import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-p-modulos-nav',
  templateUrl: './p-modulos-nav.component.html',
  styleUrls: ['./p-modulos-nav.component.scss'],
})
export class PModulosNavComponent {
  ngOnInit() {}

  avaliaUsuario() {}
}
