import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ZanattaService } from '../services/zanatta.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Subscription } from 'rxjs';
import { SnackBarConfig } from '../model/zanatta.interface';

@Component({
  selector: 'app-p-login',
  templateUrl: './p-login.component.html',
  styleUrls: ['./p-login.component.scss'],
})
export class PLoginComponent implements OnInit, OnDestroy {
  userFirebase: any;
  loading: boolean = false;
  firebaseAuthSubscription: Subscription | undefined;

  form: FormGroup = this.formBuilder.group({
    email: ['', [, Validators.maxLength(150)]],
    senha: ['', [Validators.maxLength(100)]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private zanattaService: ZanattaService,
    private afAuth: AngularFireAuth // Inject Firebase auth service
  ) {
    this.logout();

    this.firebaseAuthSubscription = this.afAuth.authState.subscribe((user) => {
      console.log('[firebase]', user);
      if (user) {
        this.userFirebase = user;

        this.showSnackBar(`Bem-vindo ${user?.email}`, 'Ok', {
          verticalPosition: 'bottom',
          horizontalPosition: 'start',
          panelClass: ['snack-bar-success'],
          duration: 5000,
        });

        this.router.navigate(['/info']);
        this.setLoading(false);
      } else {
        this.userFirebase = null;
      }
    });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.firebaseAuthSubscription?.unsubscribe();
  }

  submit() {
    if (!this.form.valid || this.loading) return;

    this.setLoading(true);

    let { email, senha } = this.form.value;
    email = email.trim();

    this.afAuth
      .signInWithEmailAndPassword(email, senha)
      .then((result) => {})
      .catch((error) => {
        this.showSnackBar('Não autorizado', 'Ok', {
          verticalPosition: 'bottom',
          horizontalPosition: 'start',
          panelClass: ['snack-bar-error'],
          duration: 5000,
        });
        this.setLoading(false);
      });
  }

  async logout() {
    await this.afAuth.signOut();
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    this.zanattaService.setLoading(this.loading);
  }

  showSnackBar(
    message: string,
    action: string,
    config: SnackBarConfig | undefined
  ) {
    this.zanattaService.showSnackBar(message, action, config);
  }
}
