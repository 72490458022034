import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZanattaService } from '../services/zanatta.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Subscription } from 'rxjs';
import { SnackBarConfig } from '../model/zanatta.interface';
import { ZanattaApiService } from '../services/zanatta-api.service';

import { Role } from 'src/app/config/niveis.enum';

@Component({
  selector: 'app-p-info',
  templateUrl: './p-info.component.html',
  styleUrls: ['./p-info.component.scss'],
})
export class PInfoComponent implements OnInit, OnDestroy {
  userFirebase: any;
  loading: boolean = false;
  firebaseAuthSubscription: Subscription | undefined;

  returnUrl = '/';
  params: any = {};

  constructor(
    private router: Router,
    private zanattaService: ZanattaService,
    private afAuth: AngularFireAuth, // Inject Firebase auth service
    private route: ActivatedRoute,
    private zanattaApi: ZanattaApiService
  ) {
    this.firebaseAuthSubscription = this.afAuth.authState.subscribe((user) => {
      console.log('[firebase]', user);
      if (user) {
        this.userFirebase = user;

        this.showSnackBar(`Bem-vindo ${user?.email}`, 'Ok', {
          verticalPosition: 'bottom',
          horizontalPosition: 'start',
          panelClass: ['snack-bar-success'],
          duration: 5000,
        });

        this.getInfo();
      } else {
        this.userFirebase = null;
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnInit(): void {
    let url = this.route?.snapshot?.queryParamMap?.get('returnUrl');
    if (url) {
      url = decodeURIComponent(url);

      const [newUrl, paramsURL] = url.split('?');

      if (paramsURL) {
        const params = paramsURL.split('&');

        params.forEach((x) => {
          const [name, value] = x.split('=');

          this.params[name] = value;
        });
      }

      this.returnUrl = `${newUrl}`;

      console.log('url to', this.returnUrl);
    }
  }

  async getInfo() {
    this.setLoading(true);

    try {
      const {
        usuario,
        centrais,
        locais,
        tiposEquipamento,
        modulos,
        situacoesEquipamento,
        situacoesLocal,
        defaultValuesEquipamentos,
        errosEquipamento,
      } = await this.zanattaApi.getConfiguracao();

      this.zanattaService.setConfiguracao({
        usuario,
        centrais,
        locais,
        tiposEquipamento,
        modulos,
        situacoesEquipamento,
        situacoesLocal,
        defaultValuesEquipamentos,
        errosEquipamento,
      });

      this.setLoading(false);

      if (this.returnUrl.length < 2) {
        console.log(usuario.nivel);

        switch (usuario.nivel) {
          case Role.USUARIO_ADMINISTRADOR:
          case Role.USUARIO_ADMINISTRADOR_STAC:
            this.returnUrl = '/admin';
            break;
          case Role.USUARIO_ZANATTA:
          case Role.USUARIO_ZANATTA_PROPRIETARIO:
          case Role.USUARIO_VISUALIZADOR:
            this.returnUrl = '/zanatta';
            break;
          default:
            break;
        }
      }

      this.router.navigate([this.returnUrl], {
        queryParams: {
          ...this.params,
        },
      });
    } catch (e: any) {
      if ((e && e.status === 401) || (e && e.status === 403)) {
        this.showSnackBar(
          'Verifique a comunicação com a internet -- lgoin',
          'Ok',
          {
            verticalPosition: 'bottom',
            horizontalPosition: 'start',
            panelClass: ['snack-bar-alert'],
          }
        );

        await this.logout();
        this.router.navigate(['/login']);
      } else {
        this.showSnackBar('Verifique a comunicação com a internet', 'Ok', {
          verticalPosition: 'bottom',
          horizontalPosition: 'start',
          panelClass: ['snack-bar-alert'],
        });
      }
    }

    this.setLoading(false);
  }

  ngOnDestroy(): void {
    this.firebaseAuthSubscription?.unsubscribe();
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    this.zanattaService.setLoading(this.loading);
  }

  showSnackBar(
    message: string,
    action: string,
    config: SnackBarConfig | undefined
  ) {
    this.zanattaService.showSnackBar(message, action, config);
  }

  async logout() {
    await this.afAuth.signOut();
  }
}
