import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take, firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AmbienciaLocal,
  AmbienciaPeriodoDia,
  TipoLeituraEstatisticas,
} from '../model/ambiencia.interface';
import { Alarme } from '../model/alarme.interface';
import { Local } from '../model/local.interface';

@Injectable({
  providedIn: 'root',
})
export class ZanattaApiService {
  api = environment.api_zanatta;

  constructor(private http: HttpClient) {}

  getConfiguracao() {
    return this.http
      .get<any>(this.api + '/configuracao')
      .pipe(take(1))
      .toPromise();
  }

  getAmbiencia({
    id_local,
    ids_tipo_leitura,
  }: {
    id_local: number;
    ids_tipo_leitura?: number[];
  }) {
    let filters = `?`;

    if (ids_tipo_leitura !== undefined) {
      filters = `${filters}ids_tipo_leitura=${ids_tipo_leitura.join(';')}`;
    }

    return this.http
      .get<any>(this.api + `/ambiencia/${id_local}/atual${filters}`)
      .pipe(take(1))
      .toPromise();
  }

  putUsuario(usuario: any) {
    return firstValueFrom(
      this.http
        .put<any>(this.api + `/usuarios`, usuario)
        .pipe(take(1))
    );
  }

  async getAmbienciaAll(
    ids: number[],
    idsTiposLeitura?: number[]
  ): Promise<AmbienciaLocal[]> {
    return new Promise(async (resolve, reject) => {
      const responseList = [];
      try {
        for (const id of ids) {
          const response = await this.getAmbiencia({
            id_local: id,
            ids_tipo_leitura: idsTiposLeitura,
          });
          responseList.push(response);
        }
        resolve(responseList);
      } catch (e) {
        reject(e);
      }
    });
  }

  async getAmbienciaAllAutomacao(locais: Local[]): Promise<AmbienciaLocal[]> {
    return new Promise(async (resolve, reject) => {
      const responseList = [];
      try {
        for (const local of locais) {
          const response = await this.getAmbiencia({
            id_local: local.id,
            ids_tipo_leitura: local.tipos_leitura_shown.map((t) => t.id),
          });
          responseList.push(response);
        }
        resolve(responseList);
      } catch (e) {
        reject(e);
      }
    });
  }

  async getAmbienciaPeriodoDia({
    id_local,
    id_sonda,
    id_tipo_leitura,
    from,
    to,
  }: {
    id_local: number;
    id_sonda?: number;
    id_tipo_leitura?: number;
    from?: string;
    to?: string;
  }): Promise<AmbienciaPeriodoDia> {
    let filter = '?';

    if (id_sonda !== undefined) filter += `id_sonda=${id_sonda}&`;

    if (id_tipo_leitura !== undefined)
      filter += `id_tipo_leitura=${id_tipo_leitura}&`;

    if (from !== undefined) filter += `from=${from}&`;

    if (to !== undefined) filter += `to=${to}&`;

    return firstValueFrom(
      this.http
        .get<any>(this.api + `/ambiencia/${id_local}/periodos${filter}`)
        .pipe(take(1))
    );
  }

  async getAmbienciaEstatisticas({
    id_local,
    id_lote,
    id_sonda,
    id_tipo_leitura,
    from,
    to,
  }: {
    id_local: number;
    id_sonda?: number;
    id_lote?: number;
    id_tipo_leitura?: number;
    from?: string;
    to?: string;
  }): Promise<TipoLeituraEstatisticas[]> {
    let filter = '?';

    if (id_sonda !== undefined) filter += `id_sonda=${id_sonda}&`;

    if (id_tipo_leitura !== undefined)
      filter += `id_tipo_leitura=${id_tipo_leitura}&`;

    if (from !== undefined) filter += `from=${from}&`;

    if (to !== undefined) filter += `to=${to}&`;

    if (id_lote !== undefined) filter += `id_lote=${id_lote}&`;

    return firstValueFrom(
      this.http
        .get<any>(this.api + `/ambiencia/${id_local}/estatisticas${filter}`)
        .pipe(take(1))
    );
  }

  async getAlarmesLocal({
    id_local,
    situacao,
    id_tipo_notificacao,
  }: {
    id_local: number;
    situacao?: boolean;
    id_tipo_notificacao?: 1 | 2;
  }): Promise<{ alarmes: Alarme[] }> {
    let filter = '?';

    if (situacao !== undefined) {
      filter += `&situacao=${situacao}`;
    }

    if (id_tipo_notificacao !== undefined) {
      filter += `&tipo=${id_tipo_notificacao}`;
    }

    return firstValueFrom(
      this.http
        .get<any>(this.api + `/locais/${id_local}/alarmes${filter}`)
        .pipe(take(1))
    );
  }

  createOrUpdateAlarmeLocal({
    id_local,
    alarme,
  }: {
    id_local: number;
    alarme: any;
  }): Observable<Alarme> {
    return this.http
      .post<any>(this.api + `/locais/${id_local}/alarmes`, alarme)
      .pipe(take(1));
  }

  getNotificacoesGeral({
    page,
    records_per_page,
    id_local,
  }: {
    id_local?: number;
    page: number;
    records_per_page: number;
  }) {
    let query = `?records_per_page=${records_per_page}&page=${page}`;
    if (id_local) query += `&id_local=${id_local}`;

    return firstValueFrom(
      this.http.get(this.api + `/alarme-notificacao${query}`).pipe(take(1))
    );
  }

  getNotificacoesAmbiencia({
    page,
    records_per_page,
    id_local,
  }: {
    id_local?: number;
    page: number;
    records_per_page: number;
  }) {
    let query = `?records_per_page=${records_per_page}&page=${page}`;
    if (id_local) query += `&id_local=${id_local}`;

    return firstValueFrom(
      this.http
        .get(this.api + `/alarme-notificacao-ambiencia${query}`)
        .pipe(take(1))
    );
  }
}
