import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PInfoComponent } from './p-info/p-info.component';
import { PLoginComponent } from './p-login/p-login.component';

import { AngularFireModule } from '@angular/fire/compat';

import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { MMaterialModule } from './m-material/m-material.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InterceptionService } from './services/interception.service';
import { PModulosNavComponent } from './p-modulos-nav/p-modulos-nav.component';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { PInfoRedirectComponent } from './p-info-redirect/p-info-redirect.component';

@NgModule({
  declarations: [
    AppComponent,
    PInfoComponent,
    PLoginComponent,
    PModulosNavComponent,
    PInfoRedirectComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,

    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    HttpClientModule,
    MMaterialModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptionService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
