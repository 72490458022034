import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { ZanattaService } from './services/zanatta.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loadingSubsCription: Subscription | undefined;
  rotuerSubsCription: Subscription | undefined;
  loading: boolean = false;

  constructor(
    private zanattaService: ZanattaService,
    private ref: ChangeDetectorRef,
    private router: Router
  ){

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.loadingSubsCription = this.zanattaService.loadingEventEmitter.subscribe(
      (value: boolean) => {
        this.loading = value;
        this.ref.detectChanges();
      }
    );

    this.rotuerSubsCription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      console.log(event)
    });
  }

  ngOnDestroy(): void {
    this.loadingSubsCription?.unsubscribe();
  }

}
