export const environment = {
  production: false,
  api_zanatta: 'https://dev.gestao.ave.clebermedeiros.com.br',
  firebase: {
    apiKey: "AIzaSyDt_n2APeBjqw8tOgJsYCVLb-EFSkpxxwc",
    authDomain: "zanatta---dev.firebaseapp.com",
    databaseURL: "https://zanatta---dev-default-rtdb.firebaseio.com",
    projectId: "zanatta---dev",
    storageBucket: "zanatta---dev.appspot.com",
    messagingSenderId: "516026819757",
    appId: "1:516026819757:web:e0a68e7ca45866960ed224",
    measurementId: "G-QEC97WTD2L"
  },
};
