export interface NivelConfig {
  id: number;
  identificacao: string;
}

export enum Role {
  USUARIO_ADMINISTRADOR = 999,
  USUARIO_ADMINISTRADOR_STAC = 888,
  USUARIO_ZANATTA = 1,
  USUARIO_ZANATTA_PROPRIETARIO = 3,
  USUARIO_VISUALIZADOR = 2,
}

export const niveisConfig = {
  999: {
    id: Role.USUARIO_ADMINISTRADOR,
    identificacao: 'Administrador',
  },
  888: {
    id: Role.USUARIO_ADMINISTRADOR_STAC,
    identificacao: 'Administrador STAC',
  },
  1: {
    id: Role.USUARIO_ZANATTA,
    identificacao: 'Usuário',
  },
  3: {
    id: Role.USUARIO_ZANATTA_PROPRIETARIO,
    identificacao: 'Usuário proprietáro',
  },
  2: {
    id: Role.USUARIO_VISUALIZADOR,
    identificacao: 'Visualizador',
  },
};

export const niveisConfigList: NivelConfig[] = Object.values(niveisConfig);
