import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarConfig } from '../model/zanatta.interface';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UsuarioResponse } from '../model/zanatta-response.interface';
import { Local } from '../model/local.interface';
import { Modulo } from '../model/modulo.interface';
import { SituacaoEquipamento } from '../model/situacaoEquipamento.interface';
import { SituacaoLocal } from '../model/situacaoLocal.interface';
import { Central } from '../model/central.interface';
import { ErroEquipamento } from '../model/error.interface';
import { ErroEquipamentoInfo } from '../model/equipamento.interface';
import infoTypesConfig from '../config/infoTypes.config';
import { Role } from '../config/niveis.enum';

interface UserFirebase {
  uid: string;
  displayName: string;
}

interface TipoEquipamento {
  id: number;
  nome: string;
}

interface EquipamentosConfig {
  config_refrigeracao_temp_max: number;
  config_refrigeracao_temp_min: number;
  config_refrigeracao_umi_max: number;
  config_refrigeracao_umi_min: number;
  config_refrigeracao_porcentagem_secagem_max: number;
  config_refrigeracao_porcentagem_secagem_min: number;
  config_fotoperiodo_programacoes_max: number;
  config_nebulizacao_umi_max: number;
  config_nebulizacao_umi_min: number;
  config_nebulizacao_programacoes_max: number;
  config_aquecimento_temp_max: number;
  config_aquecimento_temp_min: number;
  config_sombreamento_temp_max: number;
  config_sombreamento_temp_min: number;
  config_sombreamento_wm2_max: number;
  config_sombreamento_wm2_min: number;
  config_sombreamento_programacoes_max: number;
  config_gotejamento_wm2_max: number;
  config_gotejamento_wm2_min: number;
  config_gotejamento_valvulas_max: number;
  config_gotejamento_programacoes_max: number;
  config_blackout_programacoes_max: number;
  config_cortinapad_temp_max: number;
  config_cortinapad_temp_min: number;
  config_microaspersao_valvulas_max: number;
  config_microaspersao_programacoes_max: number;
  config_janelazenital_temp_max: number;
  config_janelazenital_temp_min: number;
  config_janelazenital_vento_max: number;
  config_janelazenital_vento_min: number;
  config_janelazenital_programacoes_max: number;
  config_tetoretratil_temp_max: number;
  config_tetoretratil_temp_min: number;
  config_tetoretratil_vento_max: number;
  config_tetoretratil_vento_min: number;
  config_tetoretratil_programacoes_max: number;
  config_cortinalateral_temp_max: number;
  config_cortinalateral_temp_min: number;
  config_cortinalateral_vento_max: number;
  config_cortinalateral_vento_min: number;
  config_cortinalateral_programacoes_max: number;
}

@Injectable({
  providedIn: 'root',
})
export class ZanattaService {
  loading: number = 0;
  loadingEventEmitter: EventEmitter<Boolean> = new EventEmitter();

  changedModuleEmitter: EventEmitter<Modulo> = new EventEmitter();

  usuario: UsuarioResponse | null = null;
  locais: Local[] = [];

  centrais: Central[] = [];

  localSelected: Local | undefined;

  tiposEquipamento: TipoEquipamento[] = [];

  situacoesEquipamento: SituacaoEquipamento[] = [];
  situacoesLocal: SituacaoLocal[] = [];

  modulos: Modulo[] = [];

  defaultValuesEquipamentos: EquipamentosConfig = {} as EquipamentosConfig;

  alteracaoLocal = new EventEmitter<Local>();

  errosEquipamento: ErroEquipamento[] = [];

  isVisualizador = true;

  constructor(
    private _snackBar: MatSnackBar,
    private afAuth: AngularFireAuth // Inject Firebase auth service
  ) {}

  setConfiguracao({
    locais,
    centrais,
    usuario,
    modulos,
    tiposEquipamento,
    situacoesEquipamento,
    situacoesLocal,
    defaultValuesEquipamentos,
    errosEquipamento,
  }: {
    usuario: UsuarioResponse;
    locais: Local[];
    centrais: Central[];
    tiposEquipamento: TipoEquipamento[];
    modulos: Modulo[];
    situacoesEquipamento: SituacaoEquipamento[];
    situacoesLocal: SituacaoLocal[];
    defaultValuesEquipamentos: EquipamentosConfig;
    errosEquipamento: ErroEquipamento[];
  }) {
    this.usuario = usuario;
    this.locais = locais;
    this.modulos = modulos;
    this.centrais = centrais;
    this.tiposEquipamento = tiposEquipamento;
    this.situacoesEquipamento = situacoesEquipamento;
    this.situacoesLocal = situacoesLocal;
    this.defaultValuesEquipamentos = defaultValuesEquipamentos;

    this.isVisualizador = usuario.nivel === Role.USUARIO_VISUALIZADOR;

    this.errosEquipamento = errosEquipamento ?? [];
  }

  setLocalSelected(local: Local) {
    this.localSelected = local;
    this.alteracaoLocal.emit(this.localSelected);
  }

  getLocalSelected() {
    return this.localSelected;
  }

  getErrosEquipamento() {
    return this.errosEquipamento;
  }

  getCentralLocalSelected() {
    const central =
      this.centrais.find((c) => c.id === this.localSelected.id_central) ?? null;

    if (!central) return null;

    return this.locais.find((l) => central.id_estacao === l.id) ?? null;
  }

  getLocalSelectedTipo() {
    console.log(this.localSelected?.tipo.nome);

    return this.localSelected?.tipo.nome;
  }

  getNomeUsuario() {
    return `${this.usuario?.nome} ${this.usuario?.sobrenome}`;
  }

  getUsuario() {
    return this.usuario;
  }

  setUsuario(usuario: UsuarioResponse) {
    this.usuario = usuario;
  }

  getLocais(isAmbiencia = false) {
    if (isAmbiencia) return this.locais.filter((l) => l.modulo_ambiencia);

    return this.locais;
  }

  getDefaultValuesEquipamentos() {
    return this.defaultValuesEquipamentos;
  }

  getTiposEquipamento() {
    return this.tiposEquipamento;
  }

  getSituacoesEquipamento() {
    return this.situacoesEquipamento;
  }

  getSituacoesLocal() {
    return this.situacoesLocal;
  }

  getModulos() {
    return this.modulos;
  }

  getNivelUsuario() {
    return this.usuario.nivel;
  }

  isAuthAuthorization() {
    return this.usuario !== null;
  }

  isAuthZanattaAuthorization() {
    return this.usuario !== null;
  }

  isAuthAdminAuthorization() {
    return this.usuario?.is_admin;
  }

  getIsVisualizador() {
    return this.isVisualizador;
  }

  setLoading(loading: boolean) {
    if (loading) this.loading++;
    else if (this.loading > 0) this.loading--;
    this.loadingEventEmitter.emit(this.loading > 0);
  }

  showSnackBar(
    message: string,
    action: string,
    config: SnackBarConfig | undefined
  ) {
    if (config) {
      const verticalPosition: any = config.verticalPosition || 'bottom';
      const horizontalPosition: any = config.horizontalPosition || 'start';

      // console.log('snack', {
      //   message,
      //   action,
      //   config,
      // });

      this._snackBar.open(message, action, {
        verticalPosition,
        horizontalPosition,
        panelClass: config.panelClass,
        duration: config.duration ?? 5000,
      });
    } else {
      this._snackBar.open(message, action);
    }
  }

  getCentrais() {
    return this.centrais;
  }

  logout() {
    this.afAuth.signOut();
  }

  async changeCredentials(oldSenha: string, senha: string) {
    const user = await this.afAuth.signInWithEmailAndPassword(
      this.usuario.email,
      oldSenha
    );

    await user.user.updatePassword(senha);
  }
}
