import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Importações do Angular Material
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import {
  MatLegacyPaginatorIntl,
  MatLegacyPaginatorModule,
} from '@angular/material/legacy-paginator';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { getPtBrPaginatorIntl } from '../utils/pt-br-paginator-intl';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
// { MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import {
  MatLegacyRippleModule,
  MatLegacyNativeDateModule,
} from '@angular/material/legacy-core';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';

import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';

import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';

//import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';

const importsMaterial = [
  CommonModule,
  MatLegacyProgressBarModule,
  MatLegacyButtonModule,
  MatLegacyFormFieldModule,
  MatIconModule,
  FormsModule,
  ReactiveFormsModule,
  MatLegacyInputModule,
  MatSnackBarModule,
  MatSidenavModule,
  MatToolbarModule,
  MatLegacyMenuModule,
  MatLegacyRippleModule,
  MatLegacyProgressSpinnerModule,
  MatSlideToggleModule,

  MatDialogModule,
  MatTableModule,
  MatLegacySelectModule,

  MatLegacyCheckboxModule,

  MatDatepickerModule,
  MatLegacyNativeDateModule,

  MatStepperModule,

  MatBottomSheetModule,

  MatListModule,
  //NgxMatSelectSearchModule,
  MatExpansionModule,
  MatPaginatorModule,
];

registerLocaleData(localePt);
@NgModule({
  declarations: [],
  imports: importsMaterial,
  exports: importsMaterial,
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MatPaginatorIntl, useValue: getPtBrPaginatorIntl() },
  ],
})
export class MMaterialModule {}
